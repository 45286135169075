<template>
  <main>
    <Preloader v-if="!dev" />
    <div v-else>
      <Banner
        :image="dev.images[0].largefile"
        :pageTitle="
          activeLocale == 'es' || !dev.developmentNameEn
            ? dev.developmentNameEs
            : dev.developmentNameEn
        "
      />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="main-2 col-sm-8">
              <!-- PROPERTY TITLE -->
              <div class="row">
                <div class="col-7">
                  <h1
                    v-if="dev.developmentNameEs"
                    class="property-title mt-title"
                  >
                    {{ dev.developmentNameEs }}
                    <small v-if="dev.address">{{ dev.address }}</small>
                  </h1>
                </div>
                <div class="col-5">
                  <button
                    type="button"
                    @click="goTo('master-plan')"
                    class="property-masterplan go-masterplan-button btn btn-primary mb-2 rounded-2"
                  >
                    {{ $t("pages.development_detail.masterplan_title") }}
                  </button>
                </div>
              </div>

              <!-- BEST INFORMATION -->
              <div class="property-topinfo">
                <ul class="amenities">
                  <li v-if="dev.parking != 0">
                    <i class="fas fa-car"></i> {{ dev.pkFrom }}
                    {{ dev.pkTo ? `- ${dev.pkTo}` : "" }}
                  </li>
                  <li v-if="dev.m2cFrom || dev.m2cTo">
                    <i class="icon-area"></i> {{ dev.m2cFrom }}
                    {{ dev.m2cTo ? `- ${dev.m2cTo}` : "" }} m <sup>2</sup>
                  </li>
                  <li v-if="dev.bedFrom || dev.bedTo">
                    <i class="icon-bedrooms"></i> {{ dev.bedFrom }}
                    {{ dev.bedTo ? `- ${dev.bedTo}` : "" }}
                  </li>
                  <li v-if="dev.bathFrom || dev.bathTo">
                    <i class="icon-bathrooms"></i> {{ dev.bathFrom }}
                    {{ dev.bathTo ? `- ${dev.bathTo}` : "" }}
                  </li>
                  <li v-if="dev.towers">
                    <i class="icon-bathrooms"></i> {{$t("pages.development_detail.towers")}}
                    {{ dev.towers }}
                  </li>
                </ul>
                <div v-if="dev.folio" id="property-id" class="folio">
                  # {{ dev.folio }}
                </div>
              </div>

              <!-- PRICE  -->
              <div class="row">
                <div v-if="dev.priceFrom != 0" class="col-md-6">
                  <h2>{{ $t("pages.development_detail.price_from") }}</h2>
                  <strong>
                    {{ dev.priceFrom }}
                  </strong>
                </div>
              </div>
              <br />

              <!-- CAROUSEL -->
              <DetailCarousel :items="dev.images" />
              <br />

              <!-- DESCRIPTION -->
              <div class="style1 mt-5">
                <h2 class="text-center">
                  {{ $t("pages.development_detail.description_dev") }}
                </h2>
                <div v-if="dev.descriptionEs != 0">
                  <h4>
                    {{ $t("pages.property_detail.language_description_es") }}
                  </h4>
                  <pre>
                      <p>{{ dev.descriptionEs }}</p>
                  </pre>
                </div>
                <div v-if="dev.descriptionEn != 0">
                  <h4>
                    {{ $t("pages.property_detail.language_description_en") }}
                  </h4>
                  <pre>
                      <p>{{ dev.descriptionEn }}</p>
                  </pre>
                </div>
              </div>

              <!-- AMENITIES -->
              <div
                v-if="dev.amenities || dev.services || dev.maintenance_includes"
              >
                <h2 class="text-center">
                  {{ $t("pages.property_detail.amenities_prop") }}
                </h2>
                <div class="d-md-flex mt-2 mb-2">
                  <ul class="property-amenities-list">
                    <!-- A. Interiors -->
                    <li class="list-amenities" v-if="dev.amenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_interior") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.amenities"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                    <!-- A. Exteriors -->
                    <li class="list-amenities" v-if="dev.maintenance_includes">
                      <h4>
                        {{
                          $t("pages.development_detail.maintenance_includes")
                        }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.maintenance_includes"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                    <!-- A. Services-->
                    <li class="list-amenities" v-if="dev.services">
                      <h4>
                        {{ $t("pages.property_detail.services_prop") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.services"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>

            <div class="sidebar gray col-sm-4">
              <!-- CONTACT FORM -->
              <h2 class="section-title first">
                {{ $t("pages.property_detail.contact_us") }}
              </h2>
              <div>
                <ContactForm />
              </div>

              <!-- SHARE PROPERTIES -->
              <h2 class="section-title mt-0">
                {{ $t("pages.property_detail.share_prop") }}
              </h2>
              <div class="share-wraper">
                <ul class="social-networks">
                  <li>
                    <a
                      :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-facebook" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      title="Compartir por Twitter"
                      :href="`https://twitter.com/home?status=${url}`"
                      target="_blank"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Compartir por WhatsApp"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-whatsapp"></i
                    ></a>
                  </li>
                </ul>
              </div>

              <!-- DOWNLOAD FILES -->
              <div v-if="dev.flyer.flyerEs4 || dev.flyer.flyerEs10">
                <h2 class="section-title">
                  {{ $t("pages.property_detail.share_title") }}
                </h2>
                <div class="share-wraper">
                  <ul class="banner row justify-content-center">
                    <p>{{ $t("pages.property_detail.flyers_es") }}</p>
                    <li v-if="dev.flyer.flyerEs4" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEs4"
                      >
                        <i class="fa fa-file-pdf-o"></i> 4 Fotos
                      </a>
                    </li>
                    <li v-if="dev.flyer.flyerEs10" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEs10"
                      >
                        <i class="fa fa-file-pdf-o"></i> 10 Fotos
                      </a>
                    </li>
                    <p>{{ $t("pages.property_detail.flyers_en") }}</p>
                    <li v-if="dev.flyer.flyerEs4" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEn4"
                      >
                        <i class="fa fa-file-pdf-o"></i> 4 Photos
                      </a>
                    </li>
                    <li v-if="dev.flyer.flyerEn10" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEn10"
                      >
                        <i class="fa fa-file-pdf-o"></i> 10 Phptos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- LOCATION -->
              <div class="mb-5" v-if="dev.latitude || dev.longitude">
                <div class="col-md-12 no-gutters mb-5 ">
                  <div class="col-12">
                    <h2 class="section-title">
                      {{ $t("pages.property_detail.map_title") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <GoogleMap :mapLat="dev.latitude" :mapLng="dev.longitude" />
                  </div>
                </div>
              </div>

              <!-- VIDEO -->
              <div class="mb-5" v-if="dev.video">
                <div class="col-md-12 no-gutters mt-3">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="dev.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Mapper area -->
          <div id="master-plan" class="row main-2 ">
            <div class="col-md-12 ">
              <div class="bg-white">
                <!-- Mapper -->
                <section class="row  master-plan" v-if="dev.blueprints">
                  <div class="col-md-12 text-center">
                    <div class="site-section-title">
                      <h2>
                        {{ $t("pages.development_detail.masterplan_title") }}
                      </h2>
                    </div>
                    <div class="mt-5 image-master-plan">
                      <a
                        class="example-image-link animated-link-dark"
                        :href="dev.masterplan[0].image"
                        data-lightbox="example-set"
                        :data-title="dev.developmentNameEs"
                      >
                        <img
                          class="cover"
                          :src="dev.masterplan[0].image"
                          :alt="`masterplan-${dev.developmentNameEs}`"
                          srcset=""
                        />
                      </a>
                    </div>
                    <div class="mt-5 mb-3">
                      <button
                        type="button"
                        @click="goToSection()"
                        class="btn btn-primary mb-2 rounded-2 go-masterplan-button"
                      >
                        {{
                          $t(
                            "pages.development_detail.masterplan_section_button"
                          )
                        }}
                      </button>
                    </div>
                  </div>
                </section>
                <!--section class="row mb-5" v-if="dev.blueprints">
                      <div class=" col-md-12 no-gutters">
                        <div class="row">
                          <h4 class="h4 text-black"> 
                            {{$t("pages.development_detail.mapper_title")}}
                          </h4>
                        </div>
                        <div class="row references">
                          <div class="col">
                            <div class="color-reference status-1"></div>
                            <span>Disponible</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-2"></div>
                            <span>Reservado</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-3"></div>
                            <span>No Disponible</span>
                          </div>
                        </div>

                        <div >
                          <ul class="nav nav-tabs mt-4" id="mapperTab" role="tablist">
                            <li class="nav-item" v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]">
                              <a :class="['nav-link', {'active':i==0} ]" :id="`section-tab-${i}`" data-toggle="tab" :href="`#section${i}`" role="tab" aria-controls="home" aria-selected="true">Sección {{i+1}}</a>
                            </li>
                          </ul>

                          <div class="tab-content mt-3" id="mapperTabContent">
                            <div v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]" :id="'section'+i" :class="['tab-pane fade', {'show active':i==0}]" role="tabpanel" :aria-labelledby="`section-tab-${i}`">
                              <Mapper :mapper="mapSection"/> 
                              <div id="myresult" class="img-zoom-result"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    SearchForm,
    GoogleMap,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return "$" + formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
  },
  methods: {
    goTo(ref) {
      document
        .getElementById(ref)
        .scrollIntoView({ block: "nearest", behavior: "smooth" });
    },
    goToSection() {
      let folio = this.folio;
      this.$router.push({ name: "DevelopmentSections", params: { folio } });
    },
  },
};
</script>
<style scoped>
.mb-5 {
  margin-bottom: 20px;
}

strong {
  color: #74777c;
  font-size: 36px;
  font-weight: 200;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

.for-ul {
  column-count: 3 !important;
  border-bottom: solid 1px #a7a7a7;
  padding-bottom: 15px;
}

.list-amenities {
  margin-bottom: 20px;
}

.share-wraper {
  border: 1px solid #e3e3e3;
  margin-bottom: 0px !important;
  padding: 0px 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.banners {
  float: left;
}

.banner-button {
  display: block;
  width: auto;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  border: 1px solid #919191;
  color: #4d4f52;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.banner-button:hover {
  border: 1px solid #373737;
  color: #ef4b4a;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.mt-0 {
  margin-top: -5px;
}

.folio {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #ef4b4a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif !important;
  color: #ef4b4a !important;
  font-weight: 300 !important;
  margin: 0 !important;
  /*text-rendering: optimizelegibility;*/
}

.mt-title {
  margin-top: 40px !important;
}

.property-title {
  text-transform: none !important;
  font-size: 24pt !important;
  margin-bottom: 40px !important;
  margin-top: 54px !important;
}

.sidebar.gray .section-title {
  color: #4d4f52 !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.property-masterplan {
  text-transform: none !important;
  font-size: 14px !important;
  margin-bottom: 40px !important;
  margin-top: 54px !important;
}

.go-masterplan-button {
  background: #e25b5b !important;
  border: solid 1px #e25b5b;
  margin-top: 70px !important;
  font-size: 14px !important;
  margin-bottom: 40px !important;
  color: #fff !important;
}

.go-masterplan-button:hover {
  background: transparent !important;
  color: #e25b5b !important;
}


.image-master-plan img{
  width: 100%;
  cursor: zoom-in;
} 

</style>
