<template>
  <div>
    <section class="wrapper">
      <div class="container">
        <div class="row">
          <div class="main col-sm-6 col-sm-offset-8 center">
            <div class="text-center bg-white mb-2">
              <img
                v-if="info.logo"
                class="logo mt-3 mb-2"
                :src="info.logo"
                alt="logo_web"
              />
            </div>
            <ContactForm />
          </div>


        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "@/components/layout/ContactForm.vue";
import Banner from "@/components/layout/Banner.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Banner,
    SearchForm,
    ContactForm,
  },
  data() {
    return {
      contact: true,
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>


<style scoped>
.logo {
  display: inline-block;
  max-width: 10%;
  margin-top: auto;
  margin-bottom: 20px;
}

.logo img {
  margin-top: 40%;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.color-grey{
  color: #808080;
}

@media (max-width: 980px){
  .logo{
    max-width: 22%;
  }  
} 
</style>